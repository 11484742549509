import { atom } from "recoil";

export const templateState = atom({
  key: "template",
  default: "template1",
});

export const siteDataState = atom({
  key: "siteData",
  default: {
    catalog_slug: "slug-1edited",
    sbdom: "akshay",
    favicon: "",
    loggedIn: false,
    loggedInUser: null,
    orderasguest: 0,
    gateways: [
      {
        id: "cash",
        name: "Cash",
        logo: "cash_image_cdn_full_link",
        client_id: "",
      },
    ],
    catalog_announcements: [],
    tickers: [],
    footer_links: [],
    translations: {},
    social: {
      enabled: 0,
      description: "",
      facebook: "",
      twitter: "",
      instagram: "",
      whatsapp: "",
    },
  },
});

export const loggedInState = atom({
  key: "loggedIn",
  default: false,
});

export const cartState = atom({
  key: "cartState",
  default: { count: 0 },
});
